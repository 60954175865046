.social-link {
  display: flex;
  color: var(--theme-gray);
  background-color: white;
  margin: 0.5em;
  padding: 0 1em;
  border-radius: 100px;
  width: 20em;
  /* Lighter box shadow */
  /*box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;*/
  /* Darker box shadow */
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
  text-decoration: none;
  max-width: 90vw;
  align-items: center;
  height: 3.2em;
}

.social-link-text {
  width: 100%;
  text-align: center;
}

.social-link-logo {
  height: 100%;
}
