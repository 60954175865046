#links {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 100%;
  overflow-x: hidden;

  .links__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
  }

  .links__image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
  }

  .links__click-me {
    transform: rotate(15deg);
    font-family: var(--theme-bisbee-font);
    animation: FontPulse 1.2s ease-in-out infinite alternate;
  }

  .links__title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--theme-light-text);
    text-shadow: var(--theme-text-shadow);
    gap: 1em;
  }

  .links__profile-image {
    height: 10em;
    clip-path: circle();
  }

  .links__link-container {
    display: flex;
    flex-direction: column;
  }
}
