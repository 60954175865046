/* https://www.joshwcomeau.com/css/custom-css-reset/ */

/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

/*
  2. Remove default margin
*/
* {
  margin: 0;
}

/*
  3. Allow percentage-based heights in the application
*/
html,
body {
  height: 100%;
}

/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

/*
  6. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}

/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

/*
  9. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
}

/* Styles */

.website-background {
  background: linear-gradient(45deg, #84ffc9, #aab2ff, #eca0ff);
  background-size: 600% 600%;
  animation: GradientBackground 20s ease infinite;
  overflow: scroll;

  &.dank-mode {
    background: repeat url(assets/images/weed-icon.jpg);
    animation: DiagonalScroll 5s linear infinite;
  }
}

.hidden {
  opacity: 0;
}

.fill-screen {
  height: 100%;
  width: 100%;
}

.center-children {
  display: flex;
  justify-content: center;
  align-items: center;
}

.theme-light-text {
  color: var(--theme-light-text);
}

.theme-text-shadow {
  text-shadow: var(--theme-text-shadow);
}

.theme-brand-title {
  font-family: var(--theme-bisbee-font);
  color: var(--theme-light-text);
  text-shadow: var(--theme-text-shadow);
  font-size: xxx-large;
  text-align: center;
  line-height: 0.9;
}

.center-text {
  text-align: center;
}

/* CSS variables */

:root {
  --theme-gray: #585858;
  --theme-light-text: white;
  --theme-view-horizontal-padding: 0 2em;
  --theme-shadow-color: rgba(100, 100, 111, 0.2);
  --theme-text-shadow: 0 0 5px var(--theme-shadow-color);
  --theme-bisbee-font: "Silkscreen Regular", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  --theme-bisbee-font-bold: "Silkscreen Bold", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}

/* Custom fonts */
@font-face {
  font-family: "Silkscreen Regular";
  src:
    local("Silkscreen Regular"),
    url(assets/fonts/Silkscreen/Silkscreen-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Silkscreen Bold";
  src:
    local("Silkscreen Bold"),
    url(assets/fonts/Silkscreen/Silkscreen-Bold.ttf) format("truetype");
}

/* Custom animations */
@keyframes GradientBackground {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

@keyframes DiagonalScroll {
  from {
    background-position: 50% 0;
  }

  to {
    background-position: 50% 256px;
  }
}

@keyframes FontPulse {
  from {
    font-size: medium;
  }

  to {
    font-size: larger;
  }
}
